<template lang="pug">
div.index-list-scroller.px-3
  div.index-list-scroller__chapters(v-if="currentBid")
    VBtn.mt-4(
      :color="$vuetify.theme.name === 'dark' ? 'primary-c050' : 'primary-c500'"
      variant="outlined"
      @click="selectBook(null)"
    )
      VIcon.mr-2 {{ mdiArrowLeft }}
      | {{ i18n.t("ScriptureIndex.ScriptureIndex") }}

    IndexListChapters(
      :local-tid="localTid"
      :bid="currentBid"
      :side-scroll="true"
    )

  div.index-list-scroller__books(v-else)
    IndexListBooks(
      :local-tid="localTid"
      :by-chapter-action="selectBook"
      :side-scroll="true"
    )
</template>

<script setup lang="ts">
import { ref } from "vue";
import { mdiArrowLeft } from "@mdi/js";
import { useI18n } from "vue-i18n";

const i18n = useI18n();

interface Props {
  /**
   * The ID of the user's preferred local translation.
   */
  localTid: string;
}

const props = withDefaults(defineProps<Props>(), {});

/**
 * The ID of the book whose chapters to show. If null, books rather than chapters.
 */
const currentBid = ref<string | null>(null);

function selectBook(bid: string | null) {
  currentBid.value = bid;
}
</script>
